<template>
	<div class="history-list">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功" animation-duration="800" >
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="已全部加载"
				@load="onLoad"
			>
				<div class="list-item" v-for="(item, idx) in historyList" :key="idx">
					<div  @click="gotoDetailPage(item.order,item.msgCntForCustom)">
						<div class="item-top">
							<div  class="item-left">
								<van-image
									round
									width="0.8rem"
									height="0.8rem"
									:src="!!item.agent.avatar ? 'http://' + item.agent.avatar : 'https://doc.yctop.com/weapp/zjhy/static/2021-1-26/avatar-h5.png'"
									class="top-left-img"
								/>
								<div>
									<p class="top-name">{{item.agent.name}}</p>
									<!-- <p class="top-name">哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈和</p> -->
									<p class="txt-time c9">{{item.order.created_at}}</p>
								</div>
							</div>
							<div class="unread" v-if="item.msgCntForCustom>0">{{item.msgCntForCustom}}</div>
							<!-- <div class="unread" >8</div> -->
						</div>
						<div class="c3">
							<div class="item-bottom txt">
								<p>{{item.order.dep}}-{{item.order.des}}</p><span v-if="!!item.order.volume || !!item.order.gross_weight || !!item.order.piece || !!item.order.deliverDate">：</span>
								<p v-if="!!item.order.volume">{{item.order.volume}}m³</p>
								<i class="num-line" v-if="!!item.order.volume && (!!item.order.gross_weight ||!!item.order.piece ||!!item.order.deliverDate)"></i>
								<p v-if="!!item.order.gross_weight">{{item.order.gross_weight}}KG</p>
								<i class="num-line" v-if="!!item.order.gross_weight && (!!item.order.piece ||!!item.order.deliverDate)"></i>
								<p v-if="!!item.order.piece">{{item.order.piece}}件</p>
								<i class="num-line" v-if=" !!item.order.piece && !!item.order.deliverDate"></i>
								<p v-if="!!item.order.deliverDate">{{item.order.deliverDate}}</p>
							</div>
						</div>
					</div>
				</div>
			</van-list>
		</van-pull-refresh>
	

	</div>
	
</template>
<script>
	/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	export default {
		data: ()=> ({
			historyList:[],
			loading: false,
			finished: false,
			refreshing: false,
			pageIdx:0,
			cnt:0,
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
			}),
			...mapGetters({
				login: 'checkLogin',
			})
		},
		created: function() {
			
		},
		mounted: async function() {
			this.pageIdx++
			await this.loadHistoryList('refresh')
		},
		methods: {
			loadHistoryList: async function(type) {
				let url = '/api-mall/pre-orders/list/usr',
					params={
						pageIdx:this.pageIdx
					},
					res = await REQ_HANDLE.agent.$get(url, params),
					that=this
					that.refreshing = false;
					that.loading = false;
				if(!!res) {
					that.cnt=res.data.cnt
					if(res.data.result.length>0){
						for(var i=0;i<res.data.result.length;i++){
							let temp=res.data.result[i]
							temp.order.created_at=temp.order.created_at.replace(/-/g, '.')
							temp.order.deliverDate=!!temp.order.deliver_date?that.switchDate(temp.order.deliver_date):''
						}
					}
					if(type=='refresh'){
						that.historyList = res.data.result
					}else{
						that.historyList =that.historyList.concat(res.data.result);
					}
					that.finished = that.historyList.length ==res.data.cnt
				}
			},
			switchDate: function(date){
				if(date.length>10) date=date.substring(0,10)
				let newstr="";
				newstr=date.split("-");
				if(newstr.length!=3){
					return ''
				}
				return newstr[1]+"月"+newstr[2]+"日"
			},
			gotoDetailPage: async function(item, msgCntForCustom) {
				let id = !!item ? item.id : null,
					agentId = !!item ? item.agent_id : null 
				msgCntForCustom>0 && await this.isReaded(id)
				id && !!agentId && this.$router.push('/feedback/'+agentId+'/'+ id)
			},
			onRefresh:async function(){
				this.refreshing = true;
				this.pageIdx=1
				// console.log('refresh')
				await this.loadHistoryList('refresh')
			},
			onLoad: async function(){
				// console.log('more')
				if(this.pageIdx==0){
					return
				}
				if(this.historyList.length==this.cnt){
					this.finished=true
					return
				}
				this.loading = true;
				this.pageIdx++
				await this.loadHistoryList('more')
			},
			isReaded: async function(id){
                let url = '/api-mall/pre-order-msg/read/custom/'+ id,
                    _this=this,
					res = await REQ_HANDLE.agent.$put(url)
				if(!!res){
                   await _this.loadHistoryList('refresh')
				}
            },
		}
			
	};
</script>
<style scoped >
@import "~@/assets/css/historyList.css";
</style>